import { orchestrator } from 'satcheljs';

import { getBonusRecordsAction } from '../action/get-bonus-records.action';
import { getBonusRecordsApi } from '../api/bonus/get-bonus-records.api';

orchestrator(getBonusRecordsAction, async actionMessage => {
  const { params, callback } = actionMessage;

  try {
    const response = await getBonusRecordsApi(params);
    callback?.success?.(response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
