import { orchestrator } from 'satcheljs';

import { acceptBonusAction } from '../action/accept-bonus.action';
import { postBonusAcceptApi } from '../api/bonus/post-bonus-accept.api';

orchestrator(acceptBonusAction, async actionMessage => {
  const { bonusId, callback } = actionMessage;

  try {
    if (!bonusId) {
      throw 'error.missingBonusId';
    }

    const response = await postBonusAcceptApi(bonusId);
    callback?.success?.(response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
