import { orchestrator } from 'satcheljs';

import { declineBonusAction } from '../action/decline-bonus.action';
import { postBonusDeclineApi } from '../export';

orchestrator(declineBonusAction, async actionMessage => {
  const { bonusId, callback } = actionMessage;

  try {
    if (!bonusId) {
      throw 'error.missingBonusId';
    }

    const response = await postBonusDeclineApi(bonusId);
    callback?.success?.(response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    callback?.finally?.();
  }
});
