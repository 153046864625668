import { TActionCallback } from '@totopkg/shared-util-common';
import { CrudStore, IBaseCrudItem } from '@totopkg/shared-util-crud';

import { getBonusRecordsV2Api } from '../api/bonus/get-bonus-records-v2.api';
import { postBonusAcceptApi } from '../api/bonus/post-bonus-accept.api';
import { postBonusDeclineApi } from '../api/bonus/post-bonus-decline.api';
import { IBonus } from '../type/bonus.type';

export enum EBonusAction {
  ACCEPT = 'ACCEPT',
  DECLINE = 'DECLINE',
  RECEIVE_VOUCHER = 'RECEIVE_VOUCHER'
}

export class Bonus extends CrudStore.BaseCrudData<IBonus, IBonus> {
  private static _instance: Bonus;

  private constructor() {
    super();
  }

  public static instance = () => {
    if (!this._instance) {
      this._instance = new Bonus();
    }

    return this._instance;
  };

  protected builder(): void {
    this.config({
      search: {
        default: {
          search: getBonusRecordsV2Api
        }
      },
      update: {
        default: undefined,
        [EBonusAction.ACCEPT]: id => postBonusAcceptApi(id),
        [EBonusAction.DECLINE]: id => postBonusDeclineApi(id)
      }
    });

    this.configSearch(getBonusRecordsV2Api, {
      defaultSearchParams: {
        page: 1,
        size: 15
      }
    });
  }

  public accept = (
    id: IBaseCrudItem['id'],
    options?: {
      listDataGroupId?: string;
      callback?: TActionCallback<IBonus> | undefined;
    }
  ) => {
    const { listDataGroupId, callback } = options ?? {};

    this.updateItem(id, undefined, {
      groupId: listDataGroupId,
      functionGroupId: EBonusAction.ACCEPT,
      callback
    });
  };

  public decline = (
    id: IBaseCrudItem['id'],
    options?: {
      listDataGroupId?: string;
      callback?: TActionCallback<IBonus> | undefined;
    }
  ) => {
    const { listDataGroupId, callback } = options ?? {};

    this.updateItem(id, undefined, {
      groupId: listDataGroupId,
      functionGroupId: EBonusAction.DECLINE,
      callback
    });
  };
}
