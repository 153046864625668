import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { IBonus } from '../type/bonus.type';

export const acceptBonusAction = action('acceptBonusAction', (bonusId: IBonus['id'], callback?: TActionCallback<IBonus>) => ({
  bonusId,
  callback
}));
