import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { TGetBonusRecordsParams } from '../api/bonus/get-bonus-records.api';
import { IBonus } from '../type/bonus.type';

export const getBonusRecordsAction = action(
  'getBonusRecordsAction',
  (params?: TGetBonusRecordsParams, callback?: TActionCallback<IBonus[]>) => ({
    params,
    callback
  })
);
