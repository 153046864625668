import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IBonus } from '../../type/bonus.type';

export type TGetBonusRecordsV2Params = CrudStore.TSearchParams<IBonus>;

export type TGetBonusRecordsV2Response = CrudStore.TSearchResponse<IBonus>;

export const getBonusRecordsV2Api = (params?: TGetBonusRecordsV2Params) => {
  return new Promise<TGetBonusRecordsV2Response>((resolve, reject) => {
    API.get<IBonus[]>('/account/bonusRecords/me/v2', {
      params
    })
      .then(response => resolve({ data: response.data }))
      .catch(reject);
  });
};
