import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IBonus } from '../../type/bonus.type';

export type TGetBonusRecordsParams = CrudStore.TSearchParams<IBonus>;

export type TGetBonusRecordsResponse = CrudStore.TOutdatedSearchResponse<{
  bonusRecords: IBonus[];
}>;

export const getBonusRecordsApi = (params?: TGetBonusRecordsParams) => {
  return new Promise<IBonus[]>((resolve, reject) => {
    API.get<TGetBonusRecordsResponse>('/account/bonusRecords/me', {
      params
    })
      .then(response => resolve(response.data._embedded?.bonusRecords ?? []))
      .catch(reject);
  });
};
